import React from "react";
import HeaderImg from "../../assets/images/clearviz-lenses/lens-colours/headerImg.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import Raptuve from "../../assets/images/home/raptuve.webp";
import TintData from "./TintsData";
import { ImageTextLayout } from "../../components";

const LensColours = () => {
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Lens Color header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="pt-8 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-3xl text-primary font-bold">Lens Tints</h1>
        <p className="text-base text-black leading-loose w-[98%]">
          Whether it serves as eye protection or an enhancement to your eyewear
          accessory, the color of your lenses is also very important. Here are
          the tints we offer, along with how they are beneficial to your vision.
        </p>
        <div className="my-8 flex flex-col border-t border-r border-l">
          {TintData.map((item, index) => (
            <div key={index} className="grid grid-cols-1">
              <div className="grid small:grid-rows-2 md:grid-cols-8 md:grid-rows-1">
                <h4 className="row-span-1 md:col-span-1 text-black font-bold pl-2 flex items-center">
                  {item.color}
                </h4>
                <div className="row-span-1 md:col-span-7 grid grid-cols-7 h-[80px] md:h-[90px]">
                  {item.tints.map((tints, index) => (
                    <div
                      key={index}
                      className={`
                        ${tints.bgColor} ${tints.textColor}
                        flex items-center justify-center
                      `}
                    >
                      {tints.percentage}
                    </div>
                  ))}
                </div>
              </div>
              <div className="border-t border-b py-4 px-2 text-base leading-relaxed">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ImageTextLayout
        imageSrc={Raptuve}
        subtitle="Clearviz Raptvue"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clearviz Raptvue Lenses are made to fit the modern and 
        high-demand lifestyle of nowadays, where progressive wearers have a busy 
        schedule of work, leisure, indoor and outdoor activities."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default LensColours;
