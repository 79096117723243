import HeaderImg from "../../assets/images/clearviz-lenses/lens-coating/castle-coating/headerImg.webp";
import CastleCoating1 from "../../assets/images/clearviz-lenses/lens-coating/castle-coating/castlecoating2.webp";
import CastleCoating2 from "../../assets/images/clearviz-lenses/lens-coating/castle-coating/castlecoating1.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout } from "../../components";

const CastleCoating = () => {
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Potents coating header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 md:space-y-6 px-custom-padding">
        <div>
          <h1 className="text-2xl md:text-3xl text-primary font-bold">
            Clearviz Castle Coating
          </h1>
          <h4 className="font-medium text-lg md:text-xl text-primary py-2">
            Crafted to perfection. Crafted for life.
          </h4>
        </div>
        <div className="grid grid-cols-1 gap-12 md:gap-20">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-4">
            <div className="flex flex-col gap-6">
              <p className="text-15pt text-black leading-loose w-[98%]">
                Eyegear presents Clearviz Castle coating, a premium
                antireflective coating wherein advanced treatments are applied
                to the surface of the optical lenses or components. These
                coatings are composed of thin layers of materials carefully
                selected for their optical properties. While standard
                antireflective coatings are effective, this premium version
                offers additional benefits such as increased durability, easier
                cleaning, and enhanced clarity.
              </p>
            </div>
            <div className="w-full md:w-2/4 mx-auto">
              <img src={CastleCoating1} alt="Castle Coating 1" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">
                  What it does
                </h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-loose w-[98%]">
                    The primary function of this premium antireflective coating
                    is to minimize reflections and glare. When light hits an
                    uncoated lens surface, a portion of it reflects off the
                    surface, reducing clarity and causing glare. This coating
                    alters the surface properties in a way that reduces these
                    reflections, allowing more light to pass through the lens
                    and reach the eye or optical sensor. This results in
                    improved visibility, contrast, and color accuracy,
                    especially in challenging lighting conditions.
                  </p>
                </div>
              </div>
              <div className="w-full md:hidden">
                <img src={CastleCoating2} alt="Castle Coating 2" />
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">Benefits</h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">
                      Enhanced aesthetics:{" "}
                    </strong>
                    This coating can make lenses almost invisible, eliminating
                    distracting reflections and allowing others to see the
                    wearer’s eyes more clearly. This enhances the overall
                    appearance of the eyewear.
                  </p>
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">
                      Better night vision:{" "}
                    </strong>
                    By minimizing glare from headlights and streetlights, this
                    antireflective coating improves night vision, making
                    nighttime driving safer and more comfortable.
                  </p>
                  <p className="text-base text-black leading-loose w-[98%]">
                    <strong className="text-primary">
                      Durability and convenience:{" "}
                    </strong>
                    This coating comes with added features such as scratch
                    resistance and hydrophobic properties, making the lenses
                    easier to clean and more resistant to damage. This enhances
                    the longevity of the lenses and reduces the need for
                    frequent replacements, providing long-term value to the
                    customer.
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:w-2/4 md:mx-auto">
              <img src={CastleCoating2} alt="Castle Coating 2" />
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default CastleCoating;
