import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AllRoutes } from "./constants";

const App = () => {
  const route = createBrowserRouter(AllRoutes);
  return <RouterProvider router={route} />;
};

export default App;
