import HeaderImg from "../../assets/images/clearviz-lenses/lens-coating/potens-coating/headerImg.webp";
import CastleCoating1 from "../../assets/images/clearviz-lenses/lens-coating/potens-coating/potenscoating2.webp";
import CastleCoating2 from "../../assets/images/clearviz-lenses/lens-coating/potens-coating/potenscoating1.webp";
import StoreLocator from "../../assets/images/home/store-locator.webp";
import { ImageTextLayout } from "../../components";

const PotensCoating = () => {
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Potents coating header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 md:space-y-6 px-custom-padding">
        <div>
          <h1 className="text-2xl md:text-3xl text-primary font-bold">
            Clearviz Potens Coating
          </h1>
          <h4 className="font-medium text-lg md:text-xl text-primary py-2">
            Enhancing Vision. Enhancing Life.
          </h4>
        </div>
        <div className="grid grid-cols-1 gap-12 md:gap-20">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-2 md:gap-4">
            <div className="flex flex-col gap-6">
              <p className="text-15pt text-black leading-loose w-[98%]">
                Eyegear presents Clearviz Potens coating, a premium
                scratch-resistant coating that represents the pinnacle of
                protective coatings, designed to safeguard surfaces from
                scratches and abrasions. It utilizes state-of-the-art materials
                and manufacturing processes to achieve superior durability and
                performance, compared to standard scratch-resistant coatings.
              </p>
              <p className="text-15pt text-black leading-loose w-[98%]">
                The primary function of this scratch-resistant coating is to
                provide an extremely tough and resilient barrier that shields
                the surface from scratches caused by everyday use and
                environmental factors. It acts as a sacrificial layer, absorbing
                the impact and friction that would otherwise damage the
                underlying material.
              </p>
            </div>
            <div className="w-full md:w-2/4 mx-auto">
              <img src={CastleCoating1} alt="Castle Coating 1" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">
                  What it does
                </h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-loose w-[98%]">
                    This premium scratch-resistant coating employs an advanced
                    formulation containing specialized polymers, nanoparticles,
                    or ceramic materials renowned for their exceptional hardness
                    and scratch resistance.
                  </p>
                  <p className="text-base text-black leading-loose w-[98%]">
                    These materials are engineered to create a robust protective
                    barrier that significantly reduces the susceptibility of the
                    surface to scratches and abrasions. The coating adheres
                    tightly to the surface, forming a durable and resilient
                    shield that can withstand various types of mechanical
                    stresses.
                  </p>
                </div>
              </div>
              <div className="w-full md:hidden">
                <img src={CastleCoating2} alt="Castle Coating 2" />
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">Benefits</h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-loose w-[98%]">
                    This coating provides scratch protection, ensuring that
                    surfaces remain scratch-free and pristine even after
                    prolonged use. This durability translates to extended
                    lifespan of the lenses.
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:w-2/4 md:mx-auto">
              <img src={CastleCoating2} alt="Castle Coating 2" />
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreLocator}
        title="Find a Store near You"
        description="With over 2,000 mom-and-pop stores across the nation, accessing the exceptional products 
        and services you love has never been easier. Whether you’re planning a visit or checking availability,
         we’re here to ensure a seamless and convenient shopping experience. Discover your nearest Clearviz store today!"
        buttonTxt="Call Customer Service"
        timings="10:00 AM to 09:00 PM Working Days | 10:00 AM to 06:00 PM Sundays & Holidays"
        reverse
      />
    </section>
  );
};

export default PotensCoating;
