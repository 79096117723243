import React from "react";
import HeaderImg from "../assets/images/clearviz-lenses/header.webp";
import Raptuve from "../assets/images/home/raptuve.webp";
import LensTechnologies from "../assets/images/clearviz-lenses/lenstechnologies.webp";
import LensCoating from "../assets/images/clearviz-lenses/lenscoating.webp";
import LensPersonalization from "../assets/images/clearviz-lenses/lenspersonalizaion.webp";
import { ImageTextLayout } from "../components";

const ClearvizLenses = () => {
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <div className="relative w-full h-[35vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={HeaderImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
        <div className="absolute w-full h-full bottom-0 flex items-end justify-end">
          <div className="z-1 w-2/4 md:w-2/5 flex flex-col gap-2 h-full justify-center pr-4 md:pr-6">
            <h1 className="text-lg xs:text-xl small:text-2xl md:text-3xl lg:text-4xl font-bold text-white">
              <div className="drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] uppercase">
                Experience Clearviz
              </div>
            </h1>
            <p className="font-light text-sm xs:text-base small:text-lg md:text-xl lg:text-2xl md:w-full text-border drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] text-white uppercase text-balance">
              The Pinnacle of Lens Technology for Unmatched Vision and Value
            </p>
          </div>
        </div>
      </div>

      <div className="py-8 flex flex-col gap-6 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clearviz Lenses
        </h1>
        <p className="text-base text-black leading-loose md:leading-loose w-[98%]">
          Welcome to the world of Clearviz Lenses – state-of-the-art optical
          solutions for all your visual needs. Our lenses are crafted through
          the most stringent processes and procedures, reflecting professional
          pride and offering the visual acuity you deserve.
        </p>
        <p className="text-base text-black leading-loose md:leading-loose w-[98%]">
          Clearviz lenses deliver completely reliable performance, ensuring your
          vision remains crystal clear under any circumstances. Embrace superior
          vision and enhance the quality of your life with the latest in lens
          technology by Clearviz.
        </p>
      </div>
      <ImageTextLayout
        imageSrc={Raptuve}
        subtitle="Clearviz Raptvue"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clearviz Raptvue Lenses are made to fit the modern and 
        high-demand lifestyle of nowadays, where progressive wearers have a busy 
        schedule of work, leisure, indoor and outdoor activities."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={LensTechnologies}
        title="Clearviz Technologies"
        description="Clearviz offers, in all of our lenses, ideal combinations of the following nine world-class, 
        cutting-edge technologies. Thus ensuring that your lenses provide you with uncompromisingly 
        accurate vision, in all conditions."
        buttonTxt="Find out more"
        reverse
      />
      <ImageTextLayout
        imageSrc={LensCoating}
        title="Clearviz Coatings"
        description="Different lens coatings serve various critical functions: 
        including eye health protection, convenience and durability. 
        Take a look at the coatings we have on offer."
        buttonTxt="Find out more"
        reverse
      />
      <div className="mb-[2rem]">
        <ImageTextLayout
          imageSrc={LensPersonalization}
          title="Clearviz Personalization"
          description="Get personalization like never before, with technologies 
        that perfectly match your frame, lenses and lens coatings."
          buttonTxt="Find out more"
          reverse
        />
      </div>
    </section>
  );
};

export default ClearvizLenses;
